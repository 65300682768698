import { Box, FormControlLabel, Grid, Switch } from '@mui/material';
import { ComparisonPaper } from 'assets/jss/CalculatorStyle';
import EnhancedTable from 'components/CommonComponent/CommonTable/EnhancedTable';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { portfolioComparison } from 'redux/actions/PortfolioActions';


const Comparison = (props) => {
    const { portfolioData, type } = props;
    const [comparisonDetail, setComparisonDetail] = useState({});
    const dispatch = useDispatch();
    const [showTopHolding, setShowTopHolding] = useState(false);

    const topHoldings = [
        "Symbol", "Fund Name", "Top holdings 1", "Top holdings 2", "Top holdings 3", "Top holdings 4",
        "Top holdings 5", "Top holdings 6", "Top holdings 7", "Top holdings 8",
        "Top holdings 9", "Top holdings 10", "Top holdings 1 %", "Top holdings 2 %", "Top holdings 3 %",
        "Top holdings 4 %", "Top holdings 5 %", "Top holdings 6 %", "Top holdings 7 %", "Top holdings 8 %",
        "Top holdings 9 %", "Top holdings 10 %"
    ];

    useEffect(() => {
        dispatch(portfolioComparison({ "type": type }));
    }, []);


    useEffect(() => {
        if (!portfolioData?.portfolioComparisonList || Object.keys(portfolioData.portfolioComparisonList).length === 0) return;

        if (showTopHolding) {
            const { data, column } = portfolioData.portfolioComparisonList;

            // Filter columns based on topHoldings
            const filteredColumns = column.filter((colItem) => topHoldings.includes(colItem.accessor));

            setComparisonDetail({ data: data, column: filteredColumns });
        } else {
            setComparisonDetail({
                data: portfolioData.portfolioComparisonList.data,
                column: portfolioData.portfolioComparisonList.column
            });
        }
    }, [portfolioData.portfolioComparisonList, showTopHolding]);

    useEffect(() => {
        setComparisonDetail({});
    }, []);

    return (
        <ComparisonPaper elevation={3}>
            <Grid container>
                <Grid item xs>
                    {type === undefined &&
                        <FormControlLabel
                            control={
                                <Switch checked={showTopHolding}
                                    onChange={() => setShowTopHolding((prev) => !prev)}
                                    color="primary"
                                />
                            }
                            label={showTopHolding ? 'Show All data' : 'Show Top Holdings'}
                            labelPlacement="end"
                            sx={{ marginLeft: "0px" }}
                        />
                    }
                    <Box sx={{ height: '100%', overflow: 'auto', maxHeight: '426px' }}>
                        <EnhancedTable
                            enableSearch={false}
                            enablePagination={false}
                            columns={comparisonDetail?.column || []}
                            data={comparisonDetail?.data || []}
                            enableSort={false}
                            hideToolBar={true}
                            tablestyle={{ maxHeight: 'max-content' }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </ComparisonPaper>
    );
};
export default Comparison;
