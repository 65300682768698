import * as ActionTypes from "../actions/ActionTypes";

const initialState = {
    mutualFundData: [],
    paginationInfo: {},
    loader: false,
    tickerList: [],
    comparisonList: {},
    portfolioMutualTicker: {},
    creditRatingList: {},
    closeViewPopup: false
};
const generateColorMapping = (columnData) => {
    const colorMapping = {};
    const colorPalette = ["#FF5733", "#33FF57", "#3357FF", "#FF33A8", "#A833FF", "#33FFF5"];
    let colorIndex = 0;

    const valueCount = columnData.reduce((acc, value) => {
        if (value !== null) {
            if (!acc[value]) acc[value] = 0;
            acc[value]++;
        }
        return acc;
    }, {});

    Object.keys(valueCount).forEach((value) => {
        if (valueCount[value] >= 2) {
            colorMapping[value] = {
                name: value,
                color: colorPalette[colorIndex % colorPalette.length],
            };
            colorIndex++;
        }
    });

    return colorMapping;
};



export const MutualFundReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_MUTUAL_TICKER_INIT:
        case ActionTypes.FETCH_ALL_TICKERS_INIT:
        case ActionTypes.GET_SELECTED_CREDIT_RATING_INIT:
        case ActionTypes.GET_SELECTED_MFTICKER_INIT:
            return { ...state, loader: true };
        case ActionTypes.FETCH_MUTUAL_TICKER_SUCCESS:
            return { ...state, loader: false, mutualFundData: action.data.data, paginationInfo: action.data.pagination_detail };
        case ActionTypes.FETCH_ALL_TICKERS_SUCCESS:
            return { ...state, loader: false, tickerList: action.data };
        case ActionTypes.GET_SELECTED_CREDIT_RATING_SUCCESS:
            let creditRatingData = action.data;
            let creditRatingColumnData = [];
            if (creditRatingData.length > 0) {
                let temp = {};
                creditRatingData.forEach((val, i) => {
                    const tempTicker = val.Ticker;
                    Object.keys(val).forEach((v) => {
                        if (v !== 'Ticker' && v !== 'id') {
                            if (temp[tempTicker]) {
                                const to = { [v]: val[v] };
                                temp[tempTicker] = { ...temp[tempTicker], ...to };
                            } else {
                                temp[tempTicker] = { 'Symbol': tempTicker, [v]: val[v] };
                            }
                        }
                    });
                });
                creditRatingData = Object.values(temp);
                const ignoreFields = ['id'];
                Object.keys(creditRatingData[0]).forEach((subVal) => {
                    if (!ignoreFields.includes(subVal)) {
                        creditRatingColumnData.push({
                            Header: subVal,
                            sticky: subVal === 'Symbol' ? true : false,
                            accessor: subVal,
                            disableFilters: true
                        });
                    }
                });
            }
            return { ...state, loader: false, creditRatingList: { column: creditRatingColumnData, data: creditRatingData } };
        case ActionTypes.GET_SELECTED_MFTICKER_SUCCESS:
            let filteredData = action.data;
            let formatChartData = [];
            const topHoldings = [
                "Top holdings 1", "Top holdings 2", "Top holdings 3", "Top holdings 4", 
                "Top holdings 5", "Top holdings 6", "Top holdings 7", "Top holdings 8", 
                "Top holdings 9", "Top holdings 10"
              ];
            if (filteredData.length > 0) {
                let temp = {};
                filteredData.forEach((val, i) => {
                    const tempTicker = val.Ticker;
                    Object.keys(val).forEach((v) => {
                        if (v !== 'Ticker' && v !== 'id') {
                            if (temp[tempTicker]) {
                                const to = { [v]: val[v] };
                                temp[tempTicker] = { ...temp[tempTicker], ...to };
                            } else {
                                temp[tempTicker] = { 'key spec': tempTicker, [v]: val[v] };
                            }
                        }
                    });
                });
                filteredData = Object.values(temp);
                const ignoreFields = ['id'];
                Object.keys(filteredData[0]).forEach((subVal) => {
                    let colorMapping = {}
                    if (topHoldings.includes(subVal)) {
                        const columnData = filteredData.map((row) => row[subVal]);
                        colorMapping = generateColorMapping(columnData);
                    }
                    if (!ignoreFields.includes(subVal)) {
                        formatChartData.push({
                            Header: subVal,
                            sticky: subVal === 'key spec' ? true : false,
                            accessor: subVal,
                            cellOverlapColor: colorMapping,
                            disableFilters: true
                        });
                    }
                });
            }
            return { ...state, loader: false, comparisonList: { column: formatChartData, data: filteredData } };
        case ActionTypes.SUBMIT_MUTUAL_TICKER_INIT:
            return { ...state, loader: true, portfolioMutualTicker: {} };
        case ActionTypes.SUBMIT_MUTUAL_TICKER_SUCCESS:
            return { ...state, loader: false, portfolioMutualTicker: action.data };
        case ActionTypes.SUBMIT_MUTUAL_TICKER_ERROR:
            return { ...state, loader: false, portfolioMutualTicker: {}, closeViewPopup: true };

        case ActionTypes.RESET_CLOSE_POPUP:
            return { ...state, closeViewPopup: false };

        case ActionTypes.FETCH_ALL_TICKERS_ERROR:
        case ActionTypes.FETCH_MUTUAL_TICKER_ERROR:
        case ActionTypes.GET_SELECTED_CREDIT_RATING_ERROR:
        case ActionTypes.GET_SELECTED_MFTICKER_ERROR:
            return { ...state, loader: false };
        default:
            return state;
    }
};
