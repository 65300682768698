import React, { useState, useEffect, useRef } from 'react';
import { Typography, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getLatestNews } from 'redux/actions/SettingActions';
import { AnimationTickerPaper, PlayPauseIconButton, TickerHeader, TickerList } from 'assets/jss/LandingStyle';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';



const NewsFeedView = () => {
    const dispatch = useDispatch();
    const newsData = useSelector((state) => state.setting.latestNewsList);
    const [pauseVal, setPauseVal] = useState(false);
    const firstAnimRef = useRef(null);
    const secondAnimRef = useRef(null);

    // Fetch news on load
    useEffect(() => {
        if (!newsData?.latest_news?.length) {
            dispatch(getLatestNews());
        }
    }, [dispatch, newsData]);

    // Play or pause animation
    const handlePlayPauseTicker = () => {
        if (!pauseVal) {
            firstAnimRef.current.classList.add('playPauseAnimation');
            secondAnimRef.current.classList.add('playPauseAnimation');
        } else {
            firstAnimRef.current.classList.remove('playPauseAnimation');
            secondAnimRef.current.classList.remove('playPauseAnimation');
        }
        setPauseVal(!pauseVal);
    };

    const getNewsImage = (news) => {
        switch (news?.Type) {
            case 'daily-updates-treasury':
                return '/img/treasury-bonds.jpeg';
            case 'daily-updates-corporate':
                return '/img/corporate-bonds.jpeg';
            case 'daily-updates-econ':
                return '/img/econ-market.jpg';
            case 'market_movers':
                return '/img/nasdaq-snp-dow.jpg';
            default:
                return news?.Image || '/img/logo.png';
        }
    };

    // Format time ago
    // const timeAgo = (dateString) => {
    //     if (!dateString) return 'UNKNOWN TIME';
    //     const date = new Date(dateString);
    //     const now = new Date();
    //     const secondsAgo = Math.floor((now - date) / 1000);

    //     if (secondsAgo < 60) return `${secondsAgo} SEC AGO`;
    //     if (secondsAgo < 3600) return `${Math.floor(secondsAgo / 60)} MIN AGO`;
    //     if (secondsAgo < 86400) return `${Math.floor(secondsAgo / 3600)} HOUR AGO`;
    //     return `${Math.floor(secondsAgo / 86400)} DAY AGO`;
    // };

    return (
        <AnimationTickerPaper square={true} elevation={2}>
            <TickerHeader>
                <PlayPauseIconButton size="small" aria-label="play pause ticker" component="span" onClick={handlePlayPauseTicker}>
                    {pauseVal ? <PlayArrowIcon /> : <PauseIcon />}
                </PlayPauseIconButton>
            </TickerHeader>
            <div className="tickerWrapper">
                <div className="tickerWrapperInner">
                    {[...Array(2)].map((_, idx) => {
                        let refName = firstAnimRef;
                        let classList = `firstTicker firstTickerAnimation`;
                        if (idx === 1) {
                            refName = secondAnimRef;
                            classList = `secondTicker secondTickerAnimation`;
                        }
                        return (
                            <TickerList
                                ref={refName}
                                key={idx + '_listTicker'}
                                className={classList}
                                component="ul"
                                aria-label="news ticker"
                            >
                                {newsData?.latest_news?.length !== 0
                                    ?
                                    newsData?.latest_news?.map((newsItem, i) => (
                                        <a
                                            key={i}
                                            href={`/knowledge-and-research/latest-news/${newsItem?.id
                                                ?.toLowerCase()
                                                .replace(/\s+/g, '-')
                                                .replace(/[^a-z0-9\-]/g, '')}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <ListItem component="li" className="liItems" key={i + '_newsItem'}>
                                                <ListItemIcon className="ticker-icon" sx={{ marginTop: '5px !important' }}>
                                                    <img
                                                        src={
                                                            newsItem?.Image ||
                                                            getNewsImage(newsItem)
                                                        }
                                                        alt="news-logo"
                                                        className="news-logo"
                                                    />
                                                </ListItemIcon>
                                                <ListItemText sx={{ mt: 2 }}>
                                                    <Typography variant="cardHeader" className="news-headline">
                                                        {newsItem.Headline}
                                                    </Typography>
                                                    {/* <Typography variant="caption" className="news-time">
                                                {timeAgo(newsItem['Created At'])}
                                            </Typography> */}
                                                </ListItemText>
                                            </ListItem>
                                        </a>
                                    ))
                                    :
                                    <ListItem component="li" className="liItems" key={'nonewsItem'}>
                                        <ListItemText sx={{ mt: 2 }}>
                                            <Typography variant="cardHeader" className="news-headline">
                                                Sorry, no news available at this time
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                }
                            </TickerList>
                        );
                    })}
                </div>
            </div>
        </AnimationTickerPaper>
    );
};

export default NewsFeedView;
