import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TablePaginationActions from './TablePaginationActions';
import TableSortLabel from '@mui/material/TableSortLabel';
import styles from 'assets/jss/material-kit-react/views/tablePage';
import { colorScale } from 'utils/Utils';
import CustomInput from 'components/CustomInput/CustomInput';
import { useGlobalFilter, usePagination, useRowSelect, useSortBy, useFlexLayout, useTable, useFilters } from 'react-table';
import { makeStyles } from '@mui/styles';
import { StickyTableCell } from 'assets/jss/TableStyle';
import { StyledTableCell } from 'assets/jss/TableStyle';
import { CustomTableRow } from 'assets/jss/TableStyle';
import { Box, Checkbox, Portal, Typography } from '@mui/material';
import { CustomTablePagination } from 'assets/jss/TableStyle';
import { TableStyledContainer } from 'assets/jss/TableStyle';
import { StyledMaUTable } from 'assets/jss/TableStyle';
import GlobalFilter from './GlobalFilter';
import CustomTooltip from '../CustomTooltip';
import * as tooltip from 'Tooltip.json';
import "./index.css";
import { useMemo } from 'react';

const useStyles = makeStyles(styles);
const arr = ['Ticker', 'Fund Name', 'Legal type', 'Broad Asset type'];

// Define a default UI for filtering
function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
    return (
        <CustomInput
            placeholder={`Filter data...`}
            fullWidth
            margin="normal"
            value={filterValue || ''}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
        />
    );
}
const EnhancedTable = ({
    columns,
    data,
    setData,
    showPagination,
    filter,
    setFilter,
    setColumns,
    mutualFundFilter,
    mutualFundColumnFilter,
    columnName,
    clickedCount,
    ref,
    ...rest
}) => {
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState([5, 10, 25, 50, 100]);
    //  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([5, 10, 25, 50, { label: 'All', value: data.length }]);
    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter
        }),
        []
    );
    useEffect(() => {
        //to set custom rows per page value
        if (rest.rowPageOption) setRowsPerPageOptions(rest.rowPageOption);
    }, [rest.rowPageOption]);
    useEffect(() => {
        //to set custom rows per page value
        if (data.length > 0) {
            setRowsPerPageOptions([5, 10, 25, 50, 100]);
            // setRowsPerPageOptions([5, 10, 25, 50, { label: 'All', value: data.length }]);
        }
    }, [data]);

    const validColumns = columns || [];
    const validData = data || [];

    const [sortBy, setSortBy] = useState([]);
    const sortedData = useMemo(() => {
        if (validData.length === 0 || sortBy.length === 0) return validData;

        const [{ id, desc }] = sortBy;
        const id_to_sort = id === "Price" ? "price" : id
        return [...validData].sort((a, b) => {
            if (a[id_to_sort] < b[id_to_sort]) return desc ? 1 : -1;
            if (a[id_to_sort] > b[id_to_sort]) return desc ? -1 : 1;
            return 0;
        });
    }, [validData, sortBy]);

    const {
        getTableProps,
        rows,
        headerGroups,
        prepareRow,
        page,
        gotoPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        setHiddenColumns,
        state: { pageIndex, pageSize, globalFilter, sortBy: tableSortBy }
    } = useTable(
        {
            columns: validColumns,
            data: sortedData,
            initialState: {
                hiddenColumns: ['pid', 'id']
            },
            manualSortBy: true,  // Enable manual sorting
            initialState: { sortBy },
            defaultColumn // Be sure to pass the defaultColumn option

        },
        useGlobalFilter,
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
        useFlexLayout
    );
    useEffect(() => {
        setSortBy(tableSortBy);
    }, [tableSortBy]);

    const classes = useStyles();
    const handleChangePage = (event, newPage) => gotoPage(newPage);
    const handleChangeRowsPerPage = (event) => setPageSize(Number(event.target.value));

    // handleDragEnd Method invokes when drag and drop happens, and it will update the state of the table based on dnd action taken in table.
    const handleDragEnd = (result) => {
        if (!result.destination) return;
        if (result.source.droppableId === 'droppableTableBodyRows') {
            const items = Array.from(data);
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);
            setData(items);
        }
        if (result.source.droppableId === 'droppableTableHeadCells') {
            const items = Array.from(columns);
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);
            setColumns(items);
        }
    };
    // Render the UI for your table
    useEffect(() => {
        setHiddenColumns(columns.filter((column) => column.isVisible === false).map((column) => column.accessor));
    }, [setHiddenColumns, columns]);

    let allData = Object.keys(Object(data[0])).length === 0 ? [] : rest.enablePagination && !rest.customPagination ? page : rows;
    return (
        <Box>
            {/* {!rest.hideToolBar ? (
              <TableToolbar
                  classes={classes}
                  {...rest}
                  portfolioTotal={rest.portfolioTotal}
                  addDataCompo={rest.addDataDialogCompo}
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  setGlobalFilter={setGlobalFilter}
                  globalFilter={globalFilter}
              />
          ) : (
              ''
          )} */}
            {rest.enableSearch && (
                <Portal container={rest?.searchcontainer?.current}>
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={filter == undefined ? globalFilter : filter}
                        setGlobalFilter={setFilter == undefined ? setGlobalFilter : setFilter}
                    />
                </Portal>
            )}
            <TableStyledContainer ref={ref}>
                <StyledMaUTable {...getTableProps()} customheight={rest.customheight} style={rest.tablestyle}>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <TableHead className={classes.tablestickyhead}>
                            {headerGroups.map((headerGroup) => (
                                <Droppable droppableId="droppableTableHeadCells" direction="horizontal" type="column">
                                    {(provided, snapshot) => (
                                        <CustomTableRow
                                            {...headerGroup.getHeaderGroupProps()}
                                            className={`${rest.sampleTable && 'sample-table-ui'}`}
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={{ ...headerGroup.getHeaderGroupProps().style, ...provided.droppableProps.style }}
                                        >
                                            {headerGroup.headers.map((column, index) => {
                                                const CustomTableCell =
                                                    allData.length !== 0 && (column.sticky || column.id === 'selection')
                                                        ? StickyTableCell
                                                        : StyledTableCell;
                                                const mergedProps = { ...column };
                                                const tooltipData = tooltip.default?.[column.Header] || column.tooltip;
                                                const svgTooltipColor = '#26a9e1' || '#ffffff';
                                                return (
                                                    <Draggable
                                                        key={column.id}
                                                        draggableId={`q-${column.id}`}
                                                        index={index}
                                                        isDragDisabled={!rest.columnDnd}
                                                    >
                                                        {(provided, snapshot) => {
                                                            return (
                                                                <CustomTableCell
                                                                    className={`${rest.sampleTable && 'sample-table-ui'}`}
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    {...column.getHeaderProps()}
                                                                    props={mergedProps}
                                                                    style={{
                                                                        ...column.getHeaderProps().style,
                                                                        ...provided.draggableProps.style,
                                                                        background: snapshot.isDragging ? '#e0e0e0' : 'white'
                                                                    }}
                                                                >
                                                                    <div>
                                                                        {rest.enableSort ? (
                                                                            mutualFundFilter != undefined ? (
                                                                                <span
                                                                                    onClick={() => mutualFundColumnFilter(column.Header)}
                                                                                    style={
                                                                                        column.Header === 'Action'
                                                                                            ? null
                                                                                            : { cursor: 'pointer' }
                                                                                    }
                                                                                >
                                                                                    {column.render('Header')}
                                                                                    {column.Header !== 'Action' ? (
                                                                                        <TableSortLabel
                                                                                            active={
                                                                                                column.Header.toLowerCase() ===
                                                                                                columnName.toLowerCase()
                                                                                            }
                                                                                            // react-table has a unsorted state which is not treated here
                                                                                            direction={
                                                                                                clickedCount % 3 === 0 ? 'desc' : 'asc'
                                                                                            }
                                                                                        />
                                                                                    ) : null}
                                                                                    {tooltipData &&
                                                                                        !rest.hideTooltipColumn?.includes(
                                                                                            column.Header
                                                                                        ) && (
                                                                                            <CustomTooltip
                                                                                                tooltipText={tooltipData}
                                                                                                customTooltipStyle={{ maxWidth: 450 }}
                                                                                                label={column.Header}
                                                                                                svgColor={svgTooltipColor}
                                                                                            />
                                                                                        )}
                                                                                </span>
                                                                            ) : (
                                                                                <span {...column.getSortByToggleProps()}>
                                                                                    {column.render('Header')}
                                                                                    {column.id !== 'selection' ? (
                                                                                        <TableSortLabel
                                                                                            active={column.isSorted}
                                                                                            // react-table has a unsorted state which is not treated here
                                                                                            direction={column.isSortedDesc ? 'desc' : 'asc'}
                                                                                        />
                                                                                    ) : null}
                                                                                    {tooltipData &&
                                                                                        !rest.hideTooltipColumn?.includes(
                                                                                            column.Header
                                                                                        ) && (
                                                                                            <CustomTooltip
                                                                                                tooltipText={tooltipData}
                                                                                                customTooltipStyle={{ maxWidth: 450 }}
                                                                                                label={column.Header}
                                                                                                svgColor={svgTooltipColor}
                                                                                            />
                                                                                        )}
                                                                                </span>
                                                                            )
                                                                        ) : (
                                                                            <span>{column.render('Header')}</span>
                                                                        )}
                                                                        {column.canFilter ? (
                                                                            <span className={classes.sortHeaderWrapper}>
                                                                                {' '}
                                                                                {rest.inputStyle != undefined &&
                                                                                    arr.includes(column['Header']) ? (
                                                                                    <input
                                                                                        type="text"
                                                                                        id="inputField"
                                                                                        value={rest.inputValue[column['Header']]}
                                                                                        onChange={(event) =>
                                                                                            rest.handleInputChange(column['Header'], event)
                                                                                        }
                                                                                        style={rest.inputStyle}
                                                                                        placeholder="Filter"
                                                                                    />
                                                                                ) : (
                                                                                    column.render('Filter')
                                                                                )}
                                                                            </span>
                                                                        ) : null}
                                                                    </div>
                                                                </CustomTableCell>
                                                            );
                                                        }}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </CustomTableRow>
                                    )}
                                </Droppable>
                            ))}
                        </TableHead>

                        <Droppable droppableId="droppableTableBodyRows" direction="vertical" type="row">
                            {(provided, snapshot) => (
                                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                                    {allData.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <Draggable
                                                key={`droppableRows=${i}`}
                                                draggableId={`q-droppableRows=${i}`}
                                                index={i}
                                                isDragDisabled={!rest.rowDnd}
                                            >
                                                {(provided, snapshot) => (
                                                    <CustomTableRow
                                                        {...row.getRowProps()}
                                                        hover
                                                        className={`${rest.sampleTable && 'sample-table-ui-row'}`}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{
                                                            ...row.getRowProps().style,
                                                            ...provided.draggableProps.style,
                                                            background: snapshot.isDragging ? '#e0e0e0' : 'white'
                                                        }}
                                                    >
                                                        {row.cells.map((cell) => {
                                                            const CustomTableCell =
                                                                cell.column.sticky || cell.column.id === 'selection'
                                                                    ? StickyTableCell
                                                                    : StyledTableCell;
                                                            let bgColor = '';
                                                            if (cell.column.colorScaleArr) {
                                                                bgColor = colorScale(
                                                                    cell.column.colorScaleArr,
                                                                    cell.value,
                                                                    cell?.column?.reverseSort,
                                                                    cell?.column?.isMiddleColorYellow
                                                                );
                                                            }
                                                            if (cell.column.cellOverlapColor && !cell.column.colorScaleArr) {
                                                                bgColor = cell.column?.cellOverlapColor?.[cell.value]?.color || bgColor;
                                                            }
                                                            const cellColor =
                                                                (cell.column?.cellColorArr?.includes(cell.value) && '#00acc194') || '';
                                                            let mergedProps = { ...cell, bgColor, cellColor };
                                                            if (rest.enableSelection && cell.column.id === 'Action') {
                                                                mergedProps = { display: 'flex', flexDirection: 'row' };
                                                            }
                                                            return (
                                                                <CustomTableCell {...cell.getCellProps()} props={mergedProps}>
                                                                    {rest.enableSelection && cell.column.id === 'Action' && (
                                                                        <Checkbox
                                                                            // disabled={rest.currentAction === 'BULK_EDIT'}
                                                                            checked={rest.selectedRowsSet[row.values.pid] || false}
                                                                            onChange={(e) => {
                                                                                rest.handleOnChangeSelection();
                                                                                if (e.target.checked) {
                                                                                    rest.selectedRowsSet[row.values.pid] =
                                                                                        (e.target.checked && cell.row.original) ||
                                                                                        undefined;
                                                                                } else {
                                                                                    delete rest.selectedRowsSet[row.values.pid];
                                                                                }
                                                                                rest.setSelectedRowsSet({ ...rest.selectedRowsSet });
                                                                            }}
                                                                        />
                                                                    )}
                                                                    {cell.render('Cell')}
                                                                </CustomTableCell>
                                                            );
                                                        })}
                                                    </CustomTableRow>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {rest?.loader ? (
                                        <div>Please wait...</div>
                                    ) : (
                                        allData.length === 0 && <div className={classes.noDataDiv}>No data found.</div>
                                    )}
                                    {provided.placeholder}
                                </TableBody>
                            )}
                        </Droppable>
                    </DragDropContext>
                </StyledMaUTable>
            </TableStyledContainer>
            {/* {rest.enablePagination ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }} container={rest?.container?.current}>
                    {/* <Portal container={rest?.container?.current}> 
                    <CustomTablePagination
                        showFirstButton={false}
                        showLastButton={false}
                        rowsPerPageOptions={rowsPerPageOptions}
                        // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: data.length }]}
                        //   colSpan={3}
                        count={data.length}
                        rowsPerPage={pageSize}
                        page={pageIndex}
                        SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true
                        }}
                        labelDisplayedRows={({ from, to, count }) => {
                            return from ? (
                                <Typography variant="body1">
                                    {from} - {to} of {count}
                                </Typography>
                            ) : (
                                <></>
                            );
                        }}
                        labelRowsPerPage={<Typography variant="body1">Rows per page: </Typography>}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                     </Portal>
                </Box> */}
            {showPagination == undefined && rest.enablePagination ? (
                rest.customPagination ? (
                    <Portal container={rest?.container?.current}>
                        <CustomTablePagination
                            // className="custom"
                            showFirstButton={false}
                            showLastButton={false}
                            rowsPerPageOptions={rest.customPaginationData.rowPageOption}
                            count={rest.customPaginationData.totalCount}
                            rowsPerPage={rest.customPaginationData.pageSize}
                            page={rest.customPaginationData.pageIndex}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true
                            }}
                            labelDisplayedRows={({ from, to, count }) => {
                                return from ? (
                                    <Typography variant="body1">
                                        {from} - {to} of {count}
                                    </Typography>
                                ) : (
                                    <></>
                                );
                            }}
                            labelRowsPerPage={<Typography variant="body1">Rows per page: </Typography>}
                            onPageChange={rest.customPaginationData.handleChangePage}
                            onRowsPerPageChange={rest.customPaginationData.handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </Portal>
                ) : (
                    <Portal container={rest?.container?.current}>
                        <CustomTablePagination
                            // className="custom"
                            showFirstButton={false}
                            showLastButton={false}
                            rowsPerPageOptions={rowsPerPageOptions}
                            // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: data.length }]}
                            //   colSpan={3}
                            count={data.length}
                            rowsPerPage={pageSize}
                            page={pageIndex}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true
                            }}
                            labelDisplayedRows={({ from, to, count }) => {
                                return from ? (
                                    <Typography variant="body1">
                                        {from} - {to} of {count}
                                    </Typography>
                                ) : (
                                    <></>
                                );
                            }}
                            labelRowsPerPage={<Typography variant="body1">Rows per page: </Typography>}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </Portal>
                )
            ) : (
                ''
            )}
        </Box>
    );
};
EnhancedTable.defaultProps = {
    colspan: 6,
    hideToolBar: false,
    enablePagination: true,
    columnDnd: false,
    rowDnd: false
};
EnhancedTable.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired
};

export default EnhancedTable;
