import * as ActionTypes from './ActionTypes';
import { apiCall, handleError } from 'utils/Utils';
import Globals from 'Globals.js';
import { toast } from 'react-toastify';
import { MutualFundData } from "MutualFund.json"

// handle loader when api started to call
export const submitMutualFundTickerInit = () => {
    return {
        type: ActionTypes.SUBMIT_MUTUAL_TICKER_INIT
    };
};
//handle success of api
export const submitMutualFundTickerSuccess = (val = null) => {
    return {
        type: ActionTypes.SUBMIT_MUTUAL_TICKER_SUCCESS,
        data: val
    };
};
// handle api error
export const submitMutualFundTickerError = () => {
    return {
        type: ActionTypes.SUBMIT_MUTUAL_TICKER_ERROR
    };
};
export const resetCloseViewPopup = () => {
    return {
        type: ActionTypes.RESET_CLOSE_POPUP
    };
};
/**
 * @author disha
 * @param {*} data
 * @use to submit added mutual ticker
 * @returns
 */
export const submitMutualFundTicker = (data, type) => async (dispatch) => {
    try {
        dispatch(submitMutualFundTickerInit());
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}scrapy/mutual_fund`, type, data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            toast.success(apiResponse.data.data.message);
            const argToPass = !data.from ? null : apiResponse.data.data;
            dispatch(submitMutualFundTickerSuccess(argToPass));
            if (!data.from) dispatch(fetchMutualTickerScrapping());
        } else {
            handleError(apiResponse, dispatch);
            dispatch(submitMutualFundTickerError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(submitMutualFundTickerError());
        throw error;
    }
};

//handle success of api
export const fetchMutualTickerScrappingSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_MUTUAL_TICKER_SUCCESS,
        data: val
    };
};
// handle api error
export const fetchMutualTickerScrappingError = () => {
    return {
        type: ActionTypes.FETCH_MUTUAL_TICKER_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @use to fetch the mutual fund data
 * @returns
 */
export const fetchMutualTickerScrapping = (page, pageSize, filter) => async (dispatch) => {
    const searchKey = filter === undefined ? '' : filter;
    try {
        dispatch({
            type: ActionTypes.FETCH_MUTUAL_TICKER_INIT
        });
        const apiUrl =
            page && pageSize
                ? `${Globals.API_ROOT_URL}mutualfunduser?page=${page}&per_page=${pageSize}&filter=${searchKey}`
                : `${Globals.API_ROOT_URL}mutualfunduser`;
        const apiResponse = await apiCall(apiUrl, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(fetchMutualTickerScrappingSuccess(apiResponse.data.data));
        } else {
            if (apiResponse.status === 401) {
                dispatch(fetchMutualTickerScrappingSuccess({ data: MutualFundData.data.slice((page - 1) * pageSize, page * pageSize), pagination_detail: MutualFundData.pagination_detail }));
            }
            else {
                handleError(apiResponse, dispatch);
                dispatch(fetchMutualTickerScrappingError());
            }
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(fetchMutualTickerScrappingError());
        throw error;
    }
};

export const fetchMutualFundColumnFilter = (page, pageSize, order, is_desc, value) => async (dispatch) => {
    // const is_owned = value === 'all' ? true : false;
    try {
        dispatch({
            type: ActionTypes.FETCH_MUTUAL_TICKER_INIT
        });
        const apiUrl =
            page && pageSize
                ? order
                    ? `${Globals.API_ROOT_URL}mutualfunduser?page=${page}&per_page=${pageSize}&order=${order}&is_desc=${is_desc}&is_owned=${value}`
                    : `${Globals.API_ROOT_URL}mutualfunduser?page=${page}&per_page=${pageSize}&is_desc=${is_desc}&is_owned=${value}`
                : `${Globals.API_ROOT_URL}mutualfunduser`;
        const apiResponse = await apiCall(apiUrl, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(fetchMutualTickerScrappingSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(fetchMutualTickerScrappingError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(fetchMutualTickerScrappingError());
        throw error;
    }
};

//handle success of api
export const fetchAllTickersSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_ALL_TICKERS_SUCCESS,
        data: val
    };
};
// handle api error
export const fetchAllTickersError = () => {
    return {
        type: ActionTypes.FETCH_ALL_TICKERS_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @use to fetch the all ticker data
 * @returns
 */
export const fetchAllTickers = () => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.FETCH_ALL_TICKERS_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}mutualfundscrapper`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(fetchAllTickersSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(fetchAllTickersError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(fetchAllTickersError());
        throw error;
    }
};
//handle success of api
export const getSelectedMFTickerSuccess = (val) => {
    return {
        type: ActionTypes.GET_SELECTED_MFTICKER_SUCCESS,
        data: val
    };
};
// handle api error
export const getSelectedMFTickerError = () => {
    return {
        type: ActionTypes.GET_SELECTED_MFTICKER_ERROR
    };
};
/**
 * @author disha
 * @param {*} data
 * @use to fetch the all ticker data
 * @returns
 */
export const getSelectedMFTicker = (data) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.GET_SELECTED_MFTICKER_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}scrapy/mutual_fund`, 'post', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(getSelectedMFTickerSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(getSelectedMFTickerError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(getSelectedMFTickerError());
        throw error;
    }
};

// export const resetEditTableField=val=>{
//   return {
//     type: ActionTypes.RESET_EDIT_TABLE_FIELD,
//     data:val
//   }
// }


//handle success of api
export const fetchAllFixedIncomeTickersSuccess = (val) => {
    return {
        type: ActionTypes.FETCH_ALL_TICKERS_SUCCESS,
        data: val
    };
};
// handle api error
export const fetchAllFixedIncomeTickersError = () => {
    return {
        type: ActionTypes.FETCH_ALL_TICKERS_ERROR
    };
};
export const fetchAllFixedIncomeTickers = (type="Fixed Income") => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.FETCH_ALL_TICKERS_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}fixedfundcreditrating?type=${type}`, 'get');
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(fetchAllFixedIncomeTickersSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(fetchAllFixedIncomeTickersError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(fetchAllFixedIncomeTickersError());
        throw error;
    }
};


//handle success of api
export const getSelectedCreditRatingsSuccess = (val) => {
    return {
        type: ActionTypes.GET_SELECTED_CREDIT_RATING_SUCCESS,
        data: val
    };
};
// handle api error
export const getSelectedCreditRatingsError = () => {
    return {
        type: ActionTypes.GET_SELECTED_CREDIT_RATING_ERROR
    };
};
export const getSelectedCreditRatings = (data, type="Fixed Income") => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.GET_SELECTED_CREDIT_RATING_INIT
        });
        const apiResponse = await apiCall(`${Globals.API_ROOT_URL}fixedfundcreditrating?type=${type}`, 'post', data);
        if (apiResponse !== undefined && apiResponse.status === 200) {
            dispatch(getSelectedCreditRatingsSuccess(apiResponse.data.data));
        } else {
            handleError(apiResponse, dispatch);
            dispatch(getSelectedCreditRatingsError());
        }
    } catch (error) {
        toast.error(Globals.errorMsg);
        dispatch(getSelectedCreditRatingsError());
        throw error;
    }
};
