import { Grid, Typography, Button, ButtonGroup, Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTheme } from '@mui/styles';
import { SummaryTabPaper, TopBlockBox } from 'assets/jss/PortfolioStyle';
import React, { Fragment, useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { numberWithCommas } from 'utils/Utils';
import { a11yProps } from 'utils/Utils';
import PortfolioSummary from './PortfolioSummary';
import PortfolioTabPanel from './PortfolioTabPanel';
import PortfolioCorrelation from './PortfolioCorrelation'
import TableSortLabel from '@mui/material/TableSortLabel';
import { useSelector } from 'react-redux';
import Comparison from './comparison';


const sortData = (data, key, order) =>
    [...data].sort((a, b) =>
        order === 'asc' ? a[key] - b[key] : b[key] - a[key]
    );

export default function PortfolioTopBlock(props) {
    const theme = useTheme();
    const portfolioData = useSelector((state) => state.portfolio);
    const [selectedTab, setSelectedTab] = useState(0);
    const [subSelectedTab, setSubSelectedTab] = useState(0);
    const [winnerSignChange, setWinnerSignChange] = useState(true);
    const [losserSignChange, setLosserSignChange] = useState(true);
    const [sortLosserOrder, setLosserSortOrder] = useState('asc');
    const [sortedLosserData, setSortedLosserData] = useState([]);
    const [sortWinnerOrder, setSortWinnerOrder] = useState('desc');
    const [sortedWinnerData, setSortedWinnerData] = useState([]);

    const toggleWinnerSortOrder = () => {
        setSortWinnerOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };


    useEffect(() => {
        const key = winnerSignChange ? 'dollar_change' : 'per_change';
        setSortedWinnerData(sortData(props.topfive, key, sortWinnerOrder));
    }, [props.topfive, sortWinnerOrder, winnerSignChange]);


    const toggleLosserSortOrder = () => {
        setLosserSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };

    useEffect(() => {
        const key = losserSignChange ? 'dollar_change' : 'per_change';
        setSortedLosserData(sortData(props.bottomfive, key, sortLosserOrder));
    }, [props.bottomfive, sortLosserOrder, losserSignChange]);

    const handleTabChange = (event, newValue) => setSelectedTab(newValue);
    const handleSubTabChange = (event, newValue) => setSubSelectedTab(newValue);
    //   const top5Column= useMemo(() => {
    //         const result = [
    //             {
    //                 Header: 'symbol',
    //                 accessor: 'symbol',
    //                 disableFilters: true,
    //                 width: 50,
    //                 Cell: ({ value }) => value || '-'
    //             },
    //             {
    //                 Header: 'dollar_change',
    //                 width: 50,
    //                 accessor: 'dollar_change',
    //                 disableFilters: true,
    //                 Cell: ({ value }) => value || '-'
    //             }
    //         ];
    //         return result;
    //     });
    useEffect(() => {
        if (props.isBankFile) {
            setSelectedTab(0);
        }
    }, [props.isBankFile]);
    return (
        <TopBlockBox>
            <Tabs
                className="topblock-tabs"
                value={selectedTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                // variant="scrollable"
                allowScrollButtonsMobile={true}
                aria-label="portfolio summary tabs"
            >
                <Tab label="Summary" {...a11yProps(0)} id="summary" />
                <Tab label="Allocation" {...a11yProps(1)} id="allocation" />
                <Tab label="Additional Fund Metrics" {...a11yProps(2)} id="correlation" />
                <Tab label="Comparison" {...a11yProps(3)} id="comparison" />
            </Tabs>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={selectedTab}
                onChangeIndex={(index) => setSelectedTab(index)}
            >
                <PortfolioTabPanel value={selectedTab} index={0} dir={theme.direction}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={2} className="widget-grid-item">
                            <SummaryTabPaper elevation={3}>
                                <Typography variant="subtitle1" className="summary-text">
                                    Esti. Annual Income
                                </Typography>
                                <Typography variant="subtitle1" className="summary-text">
                                    {props.annualIncome !== '' ? numberWithCommas(props.annualIncome) : 0}
                                </Typography>
                            </SummaryTabPaper>
                        </Grid>
                        <Grid item xs={12} sm={2} className="widget-grid-item">
                            <SummaryTabPaper elevation={3}>
                                <Typography variant="subtitle1" className="summary-text">
                                    Total value
                                </Typography>
                                <Typography variant="subtitle1" className="summary-text">
                                    {props.totalVal !== '' ? numberWithCommas(props.totalVal) : 0}
                                </Typography>
                            </SummaryTabPaper>
                        </Grid>
                        <Grid item xs={12} sm={2} className="widget-grid-item">
                            <SummaryTabPaper elevation={3}>
                                <Typography variant="subtitle1" className="summary-text">
                                    Day Change
                                </Typography>
                                <Typography variant="subtitle1" className="summary-text">
                                    {props.dayChange !== '' ? numberWithCommas(props.dayChange) : 0}
                                </Typography>
                                {props.avgPer && (
                                    <Typography
                                        variant="subtitle1"
                                        className={`summary-text ${Math.sign(props.avgPer) === 1 ? 'green-box' : 'error-box'}`}
                                    >
                                        {props.avgPer}%
                                    </Typography>
                                )}
                            </SummaryTabPaper>
                        </Grid>
                        <Grid item xs={12} sm={3} className="widget-grid-item">
                            <SummaryTabPaper elevation={3}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-around', mb: '10px', alignItems: 'self-end' }}>
                                    <Typography variant="subtitle1" className="summary-text-rank">
                                        Top Winners
                                        <TableSortLabel
                                            active={sortWinnerOrder === 'asc'}
                                            onClick={toggleWinnerSortOrder}
                                            direction={sortWinnerOrder === 'asc' ? 'asc' : 'desc'}
                                        />
                                    </Typography>
                                    <ButtonGroup
                                        disableElevation
                                        className="toggleButtonGroup"
                                    >
                                        <Button
                                            variant={winnerSignChange ? 'outlined' : 'contained'}
                                            className="toggleButton"
                                            onClick={() => setWinnerSignChange(false)}
                                        >
                                            %
                                        </Button>
                                        <Button
                                            variant={winnerSignChange ? 'contained' : 'outlined'}
                                            className="toggleButton"
                                            onClick={() => setWinnerSignChange(true)}
                                        >
                                            $
                                        </Button>
                                    </ButtonGroup>
                                </Box>
                                <Typography variant="subtitle1">
                                    <Grid container spacing={0.5} sx={{ pl: '25px' }}>
                                        {sortedWinnerData.slice(0, 5).map((val, i) => {  // display only top 5 symbols
                                            return (
                                                <Fragment key={i + '_topfiveloop'}>
                                                    <Grid item xs={4}>
                                                        <Typography variant="body1">{val.symbol} </Typography>
                                                    </Grid>
                                                    {winnerSignChange
                                                        // ? (val?.['dollar_change'] &&
                                                        //     numberWithCommas(val?.['dollar_change']?.toFixed(2))) || 0
                                                        ? <>
                                                            <Grid item xs={4}>
                                                                <Typography variant="subHeading1">
                                                                    {(val?.['dollar_change'] &&
                                                                        numberWithCommas(val?.['dollar_change']?.toFixed(2))) ||
                                                                        0}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography variant="subHeading1">
                                                                    {(val?.['per_change'] && numberWithCommas(val?.['per_change']?.toFixed(2))) ||
                                                                        0}
                                                                    %
                                                                </Typography>
                                                            </Grid>
                                                        </>
                                                        : <>

                                                            <Grid item xs={8}>
                                                                <Typography variant="subHeading1">
                                                                    {(val?.['per_change'] &&
                                                                        numberWithCommas(val?.['per_change']?.toFixed(2))) || 0}
                                                                    {!winnerSignChange && '%'}
                                                                </Typography>
                                                            </Grid>
                                                        </>
                                                    }
                                                </Fragment>
                                            );
                                        })}
                                    </Grid>
                                    {/* <EnhancedTable enableSearch={false} columns={top5Column} data={props.topfive} enableSort={false} /> */}
                                </Typography>
                            </SummaryTabPaper>
                        </Grid>
                        <Grid item xs={12} sm={3} className="widget-grid-item">
                            <SummaryTabPaper elevation={3}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-around', mb: '10px', alignItems: 'self-end' }}>
                                    <Typography variant="subtitle1" className="summary-text-rank">
                                        Bottom Losers
                                        <TableSortLabel
                                            active={sortLosserOrder === 'desc'}
                                            onClick={toggleLosserSortOrder}
                                            direction={sortLosserOrder === 'asc' ? 'asc' : 'desc'}
                                        />
                                    </Typography>
                                    <ButtonGroup
                                        disableElevation
                                        className="toggleButtonGroup"
                                    >
                                        <Button
                                            variant={losserSignChange ? 'outlined' : 'contained'}
                                            className="toggleButton"
                                            onClick={() => setLosserSignChange(false)}
                                        >
                                            %
                                        </Button>
                                        <Button
                                            variant={losserSignChange ? 'contained' : 'outlined'}
                                            className="toggleButton"
                                            onClick={() => setLosserSignChange(true)}
                                        >
                                            $
                                        </Button>
                                    </ButtonGroup>
                                </Box>

                                <Typography variant="subtitle1">
                                    <Grid container spacing={0.5} sx={{ pl: '25px' }}>
                                        {sortedLosserData.slice(0, 5).map((val, i) => ( // display only top 5 symbols
                                            <Fragment key={i + '_bottomfiveloop'}>
                                                <Grid item xs={4}>
                                                    <Typography variant="body1">{val.symbol}</Typography>
                                                </Grid>
                                                {/* <Grid item xs={8}>
                                                    <Typography variant="subHeading1"> */}
                                                {losserSignChange
                                                    ? <>
                                                        <Grid item xs={4}>
                                                            <Typography variant="subHeading1">
                                                                {(val?.['dollar_change'] &&
                                                                    numberWithCommas(val?.['dollar_change']?.toFixed(2))) ||
                                                                    0}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Typography variant="subHeading1">
                                                                {(val?.['per_change'] && numberWithCommas(val?.['per_change']?.toFixed(2))) ||
                                                                    0}
                                                                %
                                                            </Typography>
                                                        </Grid>
                                                    </>
                                                    : <>

                                                        <Grid item xs={8}>
                                                            <Typography variant="subHeading1">
                                                                {(val?.['per_change'] &&
                                                                    numberWithCommas(val?.['per_change']?.toFixed(2))) || 0}
                                                                {!losserSignChange && '%'}
                                                            </Typography>
                                                        </Grid>
                                                    </>
                                                }
                                                {/* </Typography>
                                                </Grid> */}
                                            </Fragment>
                                        ))}
                                    </Grid>
                                </Typography>
                            </SummaryTabPaper>
                        </Grid>
                    </Grid>
                </PortfolioTabPanel>
                {/* {!props.isBankFile && ( */}
                <PortfolioTabPanel value={selectedTab} index={1} dir={theme.direction}>
                    <PortfolioSummary
                        summaryData={props.summaryData}
                        isBankFile={props.isBankFile}
                        data={props.data}
                        columns={props.columns}
                        topHoldings={props.topHoldings}
                    />
                </PortfolioTabPanel>
                <PortfolioTabPanel value={selectedTab} index={2} dir={theme.direction}>
                    <PortfolioCorrelation
                        // summaryData={props.summaryData}
                        // isBankFile={props.isBankFile}
                        data={props.correlation}
                    // columns={props.columns}
                    />
                </PortfolioTabPanel>
                {/* // )} */}
                {/* <PortfolioTabPanel value={selectedTab} index={2} dir={theme.direction}>
                  <Grid container className={classes.uploadWrapper}>
                      <Grid item xs={12} md={6} lg={6}>
                          {/* <PortfolioUpload
                                      a11yProps={a11yProps}
                                      portList={portfolioData.portfolioList}
                                      uploadPortfolio={handlePortfolioUpload}
                                  /> *
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                          {/* <PortfolioOutput
                                      classes={classes}
                                      deleteFiles={handleFileDelete}
                                      data={portfolioData.portfolioList}
                                      updateBreadCrumb={(data) => {
                                          setRenderBreadCrumb(data);
                                      }}
                                  /> *
                      </Grid>
                  </Grid>
              </PortfolioTabPanel> */}
                <PortfolioTabPanel value={selectedTab} index={3} dir={theme.direction}>
                    <Tabs
                        className="topblock-tabs"
                        value={subSelectedTab}
                        onChange={handleSubTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        // variant="scrollable"
                        allowScrollButtonsMobile={true}
                        aria-label="portfolio comparision tabs"
                    >
                        <Tab label="All Funds" {...a11yProps(0)} id="allFunds" />
                        <Tab label="Equity" {...a11yProps(1)} id="equity" />
                        <Tab label="Fixed Income" {...a11yProps(2)} id="fixedIncome" />
                    </Tabs>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={subSelectedTab}
                        onChangeIndex={(index) => setSubSelectedTab(index)}
                    >
                        <PortfolioTabPanel value={subSelectedTab} index={0} dir={theme.direction}>
                            <Box sx={{ marginTop: '20px' }}>
                                <Comparison portfolioData={portfolioData} />
                            </Box>
                        </PortfolioTabPanel>
                        <PortfolioTabPanel value={subSelectedTab} index={1} dir={theme.direction}>
                            <Box sx={{ marginTop: '20px' }}>
                                <Comparison portfolioData={portfolioData} type={"Equities"} />
                            </Box>
                        </PortfolioTabPanel>
                        <PortfolioTabPanel value={subSelectedTab} index={2} dir={theme.direction}>
                            <Box sx={{ marginTop: '20px' }}>
                                <Comparison portfolioData={portfolioData} type={"Fixed Income"} />
                            </Box>
                        </PortfolioTabPanel>
                    </SwipeableViews>
                </PortfolioTabPanel>
            </SwipeableViews>
        </TopBlockBox>
    );
}
