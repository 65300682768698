import { Box } from '@mui/material';
import CommonToast from 'components/CommonComponent/CommonToast';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { fetchProfile, getTpeModule } from 'redux/actions/SettingActions';
import { getCookie } from 'utils/Utils';
import ChatBot from 'views/Chatbot/ChatBot';
import Cookie from 'views/Cookie';
import FeedbackIndex from 'views/Feedback';
import LandingTicker from 'views/LandingPage/LandingTicker';
import WidgetCal from 'views/Settings/WidgetCal';
import SigningRequiredDialog from 'views/SigninRequiredDialog';
import { TodoCardWrapper } from 'views/Todo/TodoCardWrapper';
import Footer from './Footer/index';
import { ScrollableEmailSub } from './Footer/ScrollableEmailSub';
import Header from './Header/Header';
import Meta from 'components/meta';
import NewsFeedView from 'views/LandingPage/NewsBlock';

const CommonLayout = () => {
    const dispatch = useDispatch();
    const setting = useSelector((state) => state.setting);
    const general = useSelector((state) => state.generalCal);

    useEffect(() => {
        if (getCookie('auth_token')) {
            dispatch(fetchProfile());
            dispatch(getTpeModule());
        }
    }, [getCookie('auth_token')]);
    const token = getCookie('auth_token');
    return (
        <>
            <Box>
                <Meta
                    title="TPE Hub - www.tpehub.com"
                    description="Welcome to the homepage of our website. Discover amazing content here."
                    page="/"
                />
                <Header setting={setting} />
                {general?.bloglist?.length > 0 && <LandingTicker />}
                <NewsFeedView/>
                <CommonToast />
                <section className="section">
                    <Outlet />
                    {token && (
                        <>
                            <TodoCardWrapper isPopup={true} />
                            <WidgetCal />
                        </>
                    )}
                    {!token && <ScrollableEmailSub />}
                    <SigningRequiredDialog />
                    <FeedbackIndex setting={setting} />
                    <ChatBot />
                    <Cookie />
                    <div id="speechify-root"></div>
                </section>
                <Footer />
            </Box>
        </>
    );
};
export default CommonLayout;
