import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import EnhancedTable from 'components/CommonComponent/CommonTable/EnhancedTable';
import { numberWithCommas } from 'utils/Utils';
import { limitWords } from 'utils/Utils';
import CustomTooltip from 'components/CommonComponent/CustomTooltip';
import { IconButton, Tooltip } from '@mui/material';
import AddNewWatchlist from './AddNewWatchlist';
import WatchListBreadcrumb from './WatchListBreadcrumb';
import { BreadcrumbsAddNewBox } from 'assets/jss/DashboardStyle';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteWatchListData } from 'redux/actions/EquityDashboardActions';
import { handleNewDataFetched } from 'redux/actions/EquityDashboardActions';
import { CustomButton } from 'assets/jss/ButtonStyle';
import WatchListChart from './WatchListChart';
import { fetchWatchListChart } from 'redux/actions/EquityDashboardActions';
import { fetchWatchlistData } from 'redux/actions/EquityDashboardActions';
import useSocket from 'utils/SocketIo';
import Globals from "Globals";

const WatchList = (props) => {
    const dispatch = useDispatch();
    const [resultData, setResult] = useState({});
    const [colorScaleArr, setColorScaleArr] = useState({});
    const [enableChart, setEnableChart] = useState({});
    const [isConnected, setIsConnected] = useState(false);
    const previousSymbols = useRef([]);
    const socket = useSocket(`${Globals.SIO_URL}`); // Replace with backend URL

    useEffect(() => {
        dispatch(fetchWatchlistData());
    }, []);

    const handleSymbolClick = (data) => {
        setEnableChart(data);
        dispatch(fetchWatchListChart(data.symbol));
    };
    const handleRangeSelection = (range) => {
        //   setEnableChart(range);
        dispatch(fetchWatchListChart(enableChart.symbol, range));
    };
    console.log('resultData', resultData);
    useEffect(() => {
        if (socket) {
          if (!isConnected) {
            socket.emit('connect_to_eodhd', { url: 'wss://ws.eodhistoricaldata.com/ws/us' });
          }

          socket.on('message', (data) => {
            console.log('message', data, "-----------------");
          });
    
          socket.on('third_party_message', (data) => {
            // console.log('message from eodhd', data, "-----------------");
            if (data?.message === "Authorized") {
              // console.log('Authorized');
              setIsConnected(true);
            } else if (data?.p) {
              // console.log('data received', data);
              let newSubWatchlistData = resultData?.subwatchlist?.map((item) => {
                if (item.symbol === data.s) {
                  let updated_item = {
                    ...item,
                    "Latest Price": parseFloat(data.p),
                    "Daily Price Change %": item["Latest Price"] ? ((parseFloat(data.p) - item["Closing Price"]) / item["Closing Price"]) * 100 : 0,
                  };
                  return updated_item;
                }
                return item;
              });

                let newData = {
                    ...resultData,
                    subwatchlist: newSubWatchlistData,
                };
              setResult(newData);
              // console.log('resultData', resultData);
            }
          });
        }
    
        // return () => {
        //   if (socket) {
        //     socket.emit('message', { action: 'unsubscribe', symbols: previousSymbols.current.join(',') });
        //     socket.emit('disconnect_from_eodhd');
        //     socket.disconnect();
        //     setIsConnected(false);
        //   }
        // };
      }, [socket, resultData]);
    
      useEffect(() => {
        if (socket && isConnected) {
          const newSymbols = resultData?.subwatchlist?.map((item) => item.symbol);
          const symbolsToUnsubscribe = previousSymbols.current?.filter(symbol => !newSymbols?.includes(symbol));
          const symbolsToSubscribe = newSymbols?.filter(symbol => !previousSymbols.current?.includes(symbol));
    
          if (symbolsToUnsubscribe?.length > 0) {
            socket.emit('message', { action: 'unsubscribe', symbols: symbolsToUnsubscribe.join(',') });
          }
    
          if (symbolsToSubscribe?.length > 0) {
            socket.emit('message', { action: 'subscribe', symbols: symbolsToSubscribe.join(',') });
          }
    
          previousSymbols.current = newSymbols;
        }
      }, [resultData, socket, isConnected]);
    const columns = React.useMemo(() => {
        return [
            {
                Header: 'Action',
                width: 50,
                Cell: ({ row }) => {
                    return (
                        <Tooltip title="Delete">
                            <IconButton
                                aria-label="delete"
                                onClick={() => {
                                    dispatch(
                                        deleteWatchListData(
                                            {
                                                id: row.original.watchlist_id,
                                                symbol: row.original.symbol,
                                                sub_watchlist_id: row.original.id
                                            },
                                            'subwatchlist',
                                            'delete',
                                            'subdelete'
                                        )
                                    );
                                    dispatch(handleNewDataFetched(true));
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    );
                }
            },
            {
                Header: 'Symbol',
                accessor: 'symbol',
                disableFilters: true,
                sticky: true,
                width: 100,
                Cell: ({ row, value }) => {
                    return (
                        <CustomButton variant="text" onClick={() => handleSymbolClick(row.original)}>
                            {value}
                        </CustomButton>
                    );
                }
            },
            {
                Header: 'Company Name',
                width: 100,
                accessor: 'Company Name',
                disableFilters: true
            },
            {
                Header: 'Latest Price',
                width: 70,
                accessor: 'Latest Price',
                Cell: ({ value, row, cell }) => numberWithCommas(value?.toFixed(2)),
                sortType: compareNumericString,
                disableFilters: true
            },
            {
                Header: 'Daily Price Change %',
                accessor: 'Daily Price Change %',
                sortType: compareNumericString,
                Cell: ({ value, row, cell }) => numberWithCommas(value?.toFixed(2)),
                colorScaleArr: colorScaleArr['Daily Price Change %'],
                isMiddleColorYellow: true,
                disableFilters: true,
                width: 70
            },
            {
                Header: 'Closing Price',
                accessor: 'Closing Price',
                sortType: compareNumericString,
                Cell: ({ value, row, cell }) => numberWithCommas(value?.toFixed(2)),
                disableFilters: true,
                width: 70
            },
            {
                Header: '5 Day Change %',
                accessor: '5 Day Change%',
                Cell: ({ value, row, cell }) => numberWithCommas(value?.toFixed(2)),
                sortType: compareNumericString,
                colorScaleArr: colorScaleArr['5 Day Change%'],
                isMiddleColorYellow: true,
                disableFilters: true,
                width: 70
            },
            {
                Header: '1 Month Change %',
                accessor: '1_month_change',
                Cell: ({ value, row, cell }) => numberWithCommas(value?.toFixed(2)),
                sortType: compareNumericString,
                colorScaleArr: colorScaleArr['1_month_change'],
                isMiddleColorYellow: true,
                disableFilters: true,
                width: 70
            },
            {
                Header: '3 Month Change %',
                accessor: '3_month_change',
                Cell: ({ value, row, cell }) => numberWithCommas(value?.toFixed(2)),
                sortType: compareNumericString,
                colorScaleArr: colorScaleArr['3_month_change'],
                isMiddleColorYellow: true,
                disableFilters: true,
                width: 70
            },
            {
                Header: '6 month Change %',
                accessor: '6_month_change',
                Cell: ({ value, row, cell }) => numberWithCommas(value?.toFixed(2)),
                sortType: compareNumericString,
                colorScaleArr: colorScaleArr['6_month_change'],
                isMiddleColorYellow: true,
                disableFilters: true,
                width: 70
            },
            {
                Header: 'YTD Change %',
                accessor: 'ytd_change',
                Cell: ({ value, row, cell }) => numberWithCommas(value?.toFixed(2)),
                sortType: compareNumericString,
                colorScaleArr: colorScaleArr['ytd_change'],
                isMiddleColorYellow: true,
                disableFilters: true,
                width: 70
            },
            {
                Header: '1 Year Change %',
                accessor: '1 Year Change%',
                Cell: ({ value, row, cell }) => numberWithCommas(value?.toFixed(2)),
                sortType: compareNumericString,
                colorScaleArr: colorScaleArr['1 Year Change%'],
                isMiddleColorYellow: true,
                disableFilters: true,
                width: 70
            },
            {
                Header: '5 Year Change %',
                accessor: '5_year_change',
                Cell: ({ value, row, cell }) => numberWithCommas(value?.toFixed(2)),
                sortType: compareNumericString,
                colorScaleArr: colorScaleArr['5_year_change'],
                isMiddleColorYellow: true,
                disableFilters: true,
                width: 70
            },
            {
                Header: 'Latest Headline',
                accessor: 'Latest Headline',
                Cell: ({ value, row, cell }) =>
                    value && limitWords(value, 5) !== value ? (
                        <CustomTooltip tooltipText={value} textTooltip={false} textLimit={5} align="left" />
                    ) : (
                        value || '-'
                    ),
                disableFilters: true
            }
        ];
    }, [colorScaleArr, resultData, props.equityDashboardData.newDataFetched]);
    function compareNumericString(rowA, rowB, id, desc) {
        let a = Number.parseFloat(rowA.values[id]);
        let b = Number.parseFloat(rowB.values[id]);
        if (Number.isNaN(a)) {
            // Blanks and non-numeric strings to bottom
            a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
        }
        if (Number.isNaN(b)) {
            b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
        }
        if (a > b) return 1;
        if (a < b) return -1;
    }
    const handleSelectedList = (val) => {
        setResult(val);
        createColorScale(val);
    };
    const createColorScale = (val) => {
        let watchListArrColorField = {};
        const watchListColorScaleFields = [
            'Daily Price Change %',
            '5 Day Change%',
            '1_month_change',
            '3_month_change',
            '6_month_change',
            'ytd_change',
            '1 Year Change%',
            '5_year_change'
        ];
        if (val.subwatchlist) {
            val.subwatchlist.forEach((subval) => {
                Object.keys(subval).forEach((scaleValue) => {
                    if (watchListColorScaleFields.indexOf(scaleValue) > -1) {
                        if (watchListArrColorField[scaleValue]) {
                            watchListArrColorField[scaleValue].push(subval[scaleValue]);
                        } else {
                            watchListArrColorField = { ...watchListArrColorField, [scaleValue]: [] };
                            watchListArrColorField[scaleValue].push(subval[scaleValue]);
                        }
                    }
                });
            });
        }
        setColorScaleArr(watchListArrColorField);
    };
    useEffect(() => {
        if (props.equityDashboardData.newDataFetched) {
            dispatch(handleNewDataFetched(false));
            const newval = props.equityDashboardData.watchlist.filter((val) => val?.id === resultData?.id)[0];
            if (newval) {
                createColorScale(newval);
                setResult(newval);
            }
        }
    }, [props.equityDashboardData.watchlist]);
    const handleDeletedList = (val) => {
        if (val.id === resultData.id) {
            setResult({});
        }
    };
    return (
        <>
            <BreadcrumbsAddNewBox>
                <WatchListBreadcrumb
                    list={props.equityDashboardData.watchlist || []}
                    selectedWatchlist={(val) => handleSelectedList(val)}
                    deleteList={(val) => handleDeletedList(val)}
                />
                <AddNewWatchlist breadCrumbValue={resultData} watchlistData={props.equityDashboardData.watchlist} />
            </BreadcrumbsAddNewBox>
            {Object.keys(enableChart).length > 0 ? (
                <WatchListChart
                    chartdetails={enableChart}
                    backButtonClicked={() => setEnableChart({})}
                    rangeSelectionClicked={(val) => handleRangeSelection(val)}
                />
            ) : (
                <EnhancedTable
                    enableSearch={false}
                    // handleLoadData={getEquityDashboardData}
                    title={'Equities'}
                    columns={columns}
                    data={resultData?.subwatchlist || []}
                    enableSort={true}
                    enablePagination={false}
                />
            )}
        </>
    );
};

export default WatchList;
