import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    Dialog,
    Divider,
    Grid,
    IconButton,
    Stack,
    Tooltip,
    Typography,
    useTheme
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import SendIcon from '@mui/icons-material/Send';
import EnhancedTable from 'components/CommonComponent/CommonTable/EnhancedTable';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getNewsFeed, submitNewsFeed, submitNewsFeedSuccess, getLatestNews } from 'redux/actions/SettingActions';
import RenderField from 'utils/RenderField';
import { numberWithCommas } from 'utils/Utils';
import { CustomButton } from 'assets/jss/ButtonStyle';
import CustomDialogTitle from 'components/DialogTitle';
import { toast } from 'react-toastify';
import { NewsFeedUrl } from 'redux/actions/SettingActions';
import SwipeableViews from 'react-swipeable-views';
import PortfolioTabPanel from 'views/PortfolioValueUpload/PortfolioTabPanel';
import Loader from 'components/CommonComponent/Loader';

const fieldsDetail = [
    {
        name: 'link',
        fieldName: 'News Link',
        fieldLabel: 'News Link  *',
        type: 'text',
        placeholder: 'Enter News Link',
        required: true,
        valid: 'noValid'
    },
    {
        name: 'title',
        fieldName: 'Headline',
        fieldLabel: 'Headline',
        type: 'text',
        placeholder: 'Enter Headline',
        required: false,
        valid: 'noValid'
    },
    {
        name: 'media',
        fieldName: 'News Source',
        fieldLabel: 'News Source',
        type: 'text',
        placeholder: 'Enter News Source',
        required: false,
        valid: 'noValid'
    },
    {
        name: 'sourceLogo',
        fieldName: 'News Source Logo',
        fieldLabel: 'News Source Logo',
        type: 'text',
        placeholder: 'Enter News Source Logo',
        required: false,
        valid: 'noValid'
    },
    {
        name: 'date',
        fieldName: 'Date',
        fieldLabel: 'Date',
        type: 'datetime',
        placeholder: 'Enter Date',
        required: false
    },
    {
        name: 'image',
        fieldName: 'Image Link',
        fieldLabel: 'Image Link',
        type: 'text',
        placeholder: 'Enter Image Link',
        required: false,
        valid: 'noValid'
    },
    {
        name: 'content',
        fieldName: 'News Content',
        fieldLabel: 'News Content',
        type: 'text',
        placeholder: 'Enter News Content',
        required: false,
        valid: 'noValid'
    },
    {
        name: 'topic',
        fieldName: 'News Topic',
        fieldLabel: 'News Topic',
        type: 'text',
        placeholder: 'Enter Topic',
        required: false,
        valid: 'noValid'
    },
    {
        name: 'type',
        fieldName: 'News Type',
        fieldLabel: 'News Type',
        type: 'select',
        placeholder: 'Select News Type',
        options: [
            { value: 'manual', label: 'Manual' },
            { value: 'rss', label: 'Rss' }
        ],
        required: false
    },
    {
        name: 'summary',
        fieldName: 'News Summary',
        fieldLabel: 'News Summary',
        type: 'text',
        placeholder: 'Enter Summary',
        required: false,
        valid: 'noValid'
    }
];
const rowsPerPageOption = [5, 10, 25];

const NewsFeedAdmin = (props) => {
    const theme = useTheme();
    const paginationContainer = useRef(null);
    const [pageSize, setPageSize] = useState(rowsPerPageOption[0]);
    const [page, setPage] = useState(0);
    const [filter, setFilter] = useState(false);
    const { setting } = props;
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [view, setView] = useState(false);
    const [type, setType] = useState('');
    const [selectedTab, setSelectedTab] = useState(0);
    const [moduleColl, setModuleColl] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const {
        handleSubmit,
        control,
        reset,
        formState: { isSubmitting },
        setValue
    } = useForm({
        mode: 'onChange'
    });

    const mergeArray = [
        ...(Array.isArray(setting?.newsList?.latest_news) ? setting.newsList.latest_news : []),
        ...(Array.isArray(setting?.newsList?.news) ? setting.newsList.news : [])
    ];

    const sortedNewsArray = mergeArray?.sort((a, b) => new Date(b['Date']) - new Date(a['Date']));

    //to submit the News Link data
    const handleOnChange = async (e) => {
        const value = e.target;
        if (value.id === 'link') {
            if (value.value.startsWith('https://')) {
                const response = await dispatch(NewsFeedUrl({ link: value?.value }));
                const formattedDate = response?.Date ? new Date(response?.Date).toISOString().split('T')[0] : null;
                setValue('title', response?.Headline);
                setValue('media', response?.['News Source']);
                setValue('sourceLogo', response?.['News Source Logo']);
                setValue('date', formattedDate);
                setValue('image', response?.Image);
                setValue('content', response?.Content);
                setValue('topic', response?.Topic);
                setValue('type', response?.Type);
                setValue('summary', response?.Summary);
            }
        }
    };

    // Handle row selection
    const handleRowSelection = (id) => {
        setSelectedRows((prev) => (prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]));
    };

    // Publish all selected articles
    const handlePublishSelected = () => {
        if (selectedRows.length === 0) {
            return toast.error('No articles selected.');
        }
        const articlesToPublish = sortedNewsArray.filter((article) => selectedRows.includes(article.id));
        onSubmit(articlesToPublish, true, true);
        toast.success('Selected articles published successfully!');
        setSelectedRows([]);
    };

    // submit the form
    const onSubmit = (data, isPublished) => {
        if (!data?.length) {
            data = [data];
        }
        let type = 'post';
        const combineArray =
            data &&
            data.map((val) => {
                // Format the date from ISO string to 'YYYY-MM-DD'
                const formattedDate =
                    val?.date || val?.Date
                        ? new Date(val.date || val.Date).toISOString().split('T')[0]
                        : new Date().toISOString().split('T')[0];

                let objToPass = {
                    title: val?.title || val?.Headline,
                    media: val?.media || val?.['News Source'],
                    media_logo: val?.sourceLogo || val?.['News Source Logo'],
                    date: formattedDate,
                    link: val?.link || val?.Link,
                    image: val?.image || val?.Image,
                    content: val?.content || val?.Content,
                    topic: val?.topic || val?.Topic,
                    type: val?.type || val?.Type,
                    summary: val?.summary || val?.Summary,
                    is_published: isPublished
                };

                if (val?.Link) {
                    if (!val?.Link || !val?.Link?.startsWith('https://')) {
                        return toast.error('Please fill News Link...');
                    }
                } else {
                    if (!val?.link && !val?.link?.startsWith('https://')) {
                        return toast.error('Please fill News Link...');
                    }
                }

                if (val?.id) {
                    objToPass = { ...objToPass, id: val.id };
                    // type = 'patch';
                }
                return objToPass;
            });

        dispatch(submitNewsFeed(combineArray, type, page, pageSize, filter));
    };

    useEffect(() => {
        // to close opened popup after submittion of api
        if (setting.newsFeedFormDialog) {
            handleClose();
            dispatch(submitNewsFeedSuccess(false));
            dispatch(getLatestNews());
        }
    }, [setting.newsFeedFormDialog]);

    useEffect(() => {
        if (setting?.newsList?.news?.length > 0) {
            const ignoreFields = ['id', 'Created At', 'is_published'];
            let column = [];
            Object.keys(setting?.newsList?.news[0]).forEach((key) => {
                if (!ignoreFields.includes(key)) {
                    const formattedKey = key.replaceAll(/_/g, ' ');
                    column.push({
                        Header: formattedKey || key,
                        accessor: key,
                        width: 150,
                        disableFilters: true,
                        Cell: ({ value }) => {
                            if (key === 'Link' && typeof value === 'string') {
                                return (
                                    <a href={value} target="_blank" rel="noopener noreferrer">
                                        {value}
                                    </a>
                                );
                            }

                            if ((key === 'Content' || 'Image') && typeof value === 'string') {
                                return value.length > 200 ? `${value.substring(0, 170)}...` : value;
                            }

                            return (
                                (!isNaN(value) && value?.toString().indexOf('.') !== -1
                                    ? numberWithCommas(value?.toFixed(2))
                                    : numberWithCommas(value)) ||
                                value ||
                                '-'
                            );
                        }
                    });
                }
            });

            column.unshift({
                Header: 'Action',
                width: 170,
                Cell: ({ row }) => (
                    <div className={'actionWrapper'}>
                        {row.original.is_published === false && (
                            <Checkbox
                                onChange={() => handleRowSelection(row.original.id)} // Toggle selection
                                color="primary"
                            />
                        )}

                        <Tooltip title="Delete">
                            <IconButton aria-label="delete" onClick={() => deleteSelectedData(row.original.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit">
                            <IconButton aria-label="edit" onClick={() => handleEditData(row.original)}>
                                <CreateIcon />
                            </IconButton>
                        </Tooltip>
                        {row.original.is_published === false && (
                            <Tooltip title="Publish">
                                <IconButton aria-label="publish" onClick={() => onSubmit(row.original, true)}>
                                    <SendIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                )
            });
            setModuleColl(column);
        }
    }, [setting.newsList.news]);

    // useEffect(() => {
    //     /// to fetch the default news feed values on page load
    //     dispatch(getNewsFeed());
    // }, []);

    const handleClickOpen = () => setOpen(true);

    // delete selected subscription plan
    const deleteSelectedData = (id) => dispatch(submitNewsFeed({ id: id }, 'delete'));

    const handleEditData = async (val) => {
        setOpen(true);
        const formattedData = {};

        for (const key in val) {
            if (Object.hasOwnProperty.call(val, key)) {
                let newKey;
                if (key === 'Headline') {
                    newKey = 'title';
                } else if (key === 'News Source') {
                    newKey = 'media';
                } else if (key === 'News Source Logo') {
                    newKey = 'sourceLogo';
                } else {
                    newKey = key.charAt(0).toLowerCase() + key.slice(1);
                }

                formattedData[newKey] = val[key];
            }
        }

        for (const key in formattedData) {
            setValue(key, formattedData[key]);
        }
    };

    //close popup
    const handleClose = () => {
        const resetObj = fieldsDetail.reduce((acc, cur) => ({ ...acc, [cur.name]: '' }), {});
        reset(resetObj || {});
        setOpen(false);
    };
    const handleClickView = (type) => {
        setView(true);
        setType(type);
    };
    const handleCloseView = () => {
        setView(false);
        setType('');
    };

    useEffect(() => {
        fetchNewsFeed();
    }, [page, pageSize, filter]);

    const fetchNewsFeed = () => {
        const getData = setTimeout(() => {
            dispatch(getNewsFeed(page + 1, pageSize, filter));
        }, 2000);

        return () => clearTimeout(getData);
    };

    const handleChangePage = (e, n) => {
        setPage(n);
    };
    const handleChangeRowsPerPage = (e) => {
        setPageSize(Number(e.target.value));
    };

    const handleFilterChange = (e) => {
        const val = e.target.value;
        if (val === 'draft') {
            setFilter(false);
        } else if (val === 'published') {
            setFilter(true);
        }
    };

    return (
        <>
            <Card elevation={2}>
                {setting.loader ? <Loader /> : ''}
                <CardContent>
                    <Box display="flex" justifyContent="space-between">
                        <Tooltip title="Add" arrow>
                            <CustomButton
                                variant="text"
                                className="read-more"
                                startIcon={<AddIcon />}
                                aria-label="add"
                                onClick={handleClickOpen}
                            >
                                Add New Data
                            </CustomButton>
                        </Tooltip>
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Button onClick={handlePublishSelected} disabled={selectedRows.length === 0}>
                                Publish Selected
                            </Button>
                            <Grid item xs={12} sm={5} sx={{ mt: '-18px' }}>
                                <RenderField
                                    handleOnChange={(e) => handleFilterChange(e)}
                                    name={'filter'}
                                    control={control}
                                    id={'filter'}
                                    type={'select'}
                                    options={[
                                        { label: 'Draft', value: 'draft' },
                                        { label: 'Published', value: 'published' }
                                    ]}
                                    defaultValue={'draft'}
                                    style={{
                                        width: '110px',
                                        marginRight: '20px',
                                        float: 'inline-end'
                                    }}
                                />
                            </Grid>
                        </Box>
                    </Box>
                    {/* News Counts */}
                    <Box
                        sx={{
                            color: 'darkgrey',
                            fontSize: '0.85rem',
                            mt: 1,
                        }}
                    >
                            Published: <span style={{ fontWeight: 'bold' }}>{setting?.newsList?.count?.published} | </span>
                            Drafted: <span style={{ fontWeight: 'bold' }}>{setting?.newsList?.count?.drafted} | </span>
                            Deleted: <span style={{ fontWeight: 'bold' }}>{setting?.newsList?.count?.deleted} </span>
                    </Box>

                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <CustomDialogTitle id="form-dialog-title" onClose={handleClose}>
                            Add News Feed
                        </CustomDialogTitle>
                        <Divider />
                        <CustomDialogTitle>
                            <form onSubmit={handleSubmit((data) => onSubmit(data, false))}>
                                <Grid container>
                                    <Alert sx={{ marginLeft: '15px' }} severity="info" color="info">
                                        If you enter your News link, We will automatically populate the data available in the link
                                    </Alert>
                                    {fieldsDetail.map((val, i) => {
                                        return (
                                            <Fragment key={i + '_newsFeed'}>
                                                <Grid item xs={12} sm={4} alignSelf={'center'}>
                                                    <Typography variant="body2" component="p" className="outputLabel">
                                                        {val.fieldLabel}
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={val?.name === 'content' || val?.name === 'summary' ? 7.3 : 8}
                                                    position="relative"
                                                >
                                                    <RenderField
                                                        name={val.name}
                                                        control={control}
                                                        label={''}
                                                        disableEmptyValue={true}
                                                        fieldName={val.fieldName}
                                                        id={val.name}
                                                        timeFormat={false}
                                                        handleOnChange={handleOnChange}
                                                        type={val.type}
                                                        options={val.options}
                                                        required={val.required || true}
                                                        customPatternName={val.custompattern}
                                                        placeholder={val.placeholder}
                                                        setValue={setValue}
                                                        valid={val.valid}
                                                        {...val}
                                                    />
                                                    {val?.name === 'content' || val?.name === 'summary' ? (
                                                        <Tooltip title="Read More">
                                                            <IconButton
                                                                onClick={() =>
                                                                    handleClickView(
                                                                        val?.name === 'content' ? 'C' : val?.name === 'summary' ? 'S' : ''
                                                                    )
                                                                }
                                                                sx={{
                                                                    color: '#8f8fa2',
                                                                    padding: '2px',
                                                                    position: 'absolute',
                                                                    top: '13px',
                                                                    left: '335px'
                                                                }}
                                                                className="infoButton"
                                                            >
                                                                <InfoIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    ) : (
                                                        ''
                                                    )}
                                                </Grid>
                                            </Fragment>
                                        );
                                    })}
                                </Grid>
                                <Stack
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="center"
                                    gap="10px"
                                    marginTop="10px"
                                >
                                    <Button
                                        variant="contained"
                                        color="success"
                                        size="xs"
                                        disabled={isSubmitting}
                                        type="button"
                                        onClick={() => handleSubmit((data) => onSubmit(data, false))()}
                                    >
                                        Draft
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={() => handleSubmit((data) => onSubmit(data, true))()}
                                        color="info"
                                        disabled={isSubmitting}
                                        size="xs"
                                        type="button"
                                    >
                                        Publish
                                    </Button>
                                </Stack>
                            </form>
                        </CustomDialogTitle>
                    </Dialog>
                    <Dialog aria-labelledby="form-dialog-title" open={view} onClose={handleCloseView}>
                        <CustomDialogTitle id="form-dialog-title" onClose={handleCloseView}>
                            {'Edit Details'}
                        </CustomDialogTitle>
                        <Divider />
                        <Card
                            sx={{
                                maxWidth: 525,
                                minWidth: 515,
                                minHeight: 300,
                                maxHeight: 850,
                                position: 'relative',
                                overflow: 'visible',
                                '@medai (max-width: 767px)': {
                                    minWidth: 275
                                }
                            }}
                        >
                            <Grid item xs={12} sm={11} height="200px">
                                <RenderField
                                    BootstrapInput={true}
                                    name={type === 'C' ? 'content' : type === 'S' ? 'summary' : ''}
                                    control={control}
                                    label={type === 'C' ? 'Content' : type === 'S' ? 'Summary' : ''}
                                    id={type === 'C' ? 'content' : type === 'S' ? 'summary' : ''}
                                    type="text"
                                    // required
                                    multiline
                                    rows={10}
                                    // maxRows={4}
                                    // maxLength={250}
                                    customPatternName={/^([a-z0-9A-Z!? '. ;]{1,9999999999})$/g}
                                    placeholder={type === 'C' ? 'Enter your Content here.' : type === 'S' ? 'Enter your Summary here.' : ''}
                                    setValue={setValue}
                                    valid="noValid"
                                />
                            </Grid>
                        </Card>
                    </Dialog>
                    <SwipeableViews
                        index={selectedTab}
                        onChangeIndex={(index) => setSelectedTab(index)}
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    >
                        {setting?.newsList?.news?.length > 0 ? (
                            <PortfolioTabPanel dir={theme.direction}>
                                <EnhancedTable
                                    enableSearch={false}
                                    container={paginationContainer}
                                    columns={moduleColl}
                                    data={sortedNewsArray || []}
                                    enableSort={true}
                                    setFilter={setFilter}
                                    filter={filter}
                                    rowPageOption={rowsPerPageOption}
                                    customPagination={true}
                                    customPaginationData={{
                                        rowPageOption: rowsPerPageOption,
                                        totalCount: setting?.newsList?.pagination?.total || 50,
                                        pageSize: pageSize,
                                        pageIndex: page,
                                        handleChangePage: handleChangePage,
                                        handleChangeRowsPerPage: handleChangeRowsPerPage
                                    }}
                                />
                                <Box
                                    ref={paginationContainer}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        '.css-tsnvvp-MuiTablePagination-displayedRows': {
                                            right: '35px'
                                        }
                                    }}
                                />
                            </PortfolioTabPanel>
                        ) : (
                            <NoDataFound msg="Result will be shown after adding subscription plan." />
                        )}
                    </SwipeableViews>
                </CardContent>
            </Card>
        </>
    );
};

export default NewsFeedAdmin;
