import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Box, Grid, TextField } from '@mui/material';
import { Checkbox, Paper, Typography } from '@mui/material';
import { ComparisonPaper } from 'assets/jss/CalculatorStyle';
import EnhancedTable from 'components/CommonComponent/CommonTable/EnhancedTable';
import Button from 'components/CustomButtons/Button';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getSelectedCreditRatings } from 'redux/actions/MutualFundActions';
import { fetchAllFixedIncomeTickers } from 'redux/actions/MutualFundActions';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const CreditRating = (props) => {
    const { mutualdata, type } = props;
    const [value, setValue] = useState([]);
    const [tickerList, setTickerList] = useState([]);
    const [comparisonDetail, setComparisonDetail] = React.useState({});
    const [searchData, setSearchData] = React.useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAllFixedIncomeTickers(type));
    }, []); 

    // handle the search button and convert selected ticker into table format
    const handleTickerSearch = () => {
        const ids = value.map((data) => data.value);
        if(ids.length === 0){
            toast.error("Please select alteast one ticker")
            return;
        }
        dispatch(getSelectedCreditRatings({ tickers: ids }, type));
    };
    useEffect(() => {
        if (Object.keys(mutualdata.creditRatingList).length > 0) {
            setComparisonDetail({
                data: mutualdata.creditRatingList.data,
                column: mutualdata.creditRatingList.column
            });
            setSearchData(mutualdata.creditRatingList.data);
        }
    }, [mutualdata.creditRatingList]);
    useEffect(() => {
        if (mutualdata.tickerList) {
            const list = mutualdata.tickerList.map((val) => ({
                value: val.id,
                label: val.Ticker + ` (${val['Fund Name']})`
            }));
            setTickerList(list);
        }
    }, [mutualdata.tickerList]);
    useEffect(() => {
        setComparisonDetail({});
        setSearchData([]);
    }, []);

    return (
        <>
            <Paper elevation={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={8} style={{ paddingTop: '0px', margin: '10px' }}>
                        <Autocomplete
                            multiple
                            id="mutualfund_comp_list"
                            value={value || []}
                            options={tickerList}
                            disableCloseOnSelect
                            limitTags={3}
                            onChange={(event, newValue) => {
                                if (newValue.length <= 10) {
                                    setValue(newValue);
                                } else {
                                    return toast.error('Maximum selection of tickers reached');
                                }
                            }}
                            getOptionLabel={(option) => option.label?.toString()}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                    {option.label}
                                </li>
                            )}
                            renderInput={(params) => <TextField {...params} label="Tickers" placeholder="Select/Search ticker(s)" />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} display="flex" justifyContent="center" style={{ paddingTop: '0px', margin: 'auto' }}>
                        <Button variant="contained" size="sm" color="info" onClick={() => handleTickerSearch()}>
                            Comparison
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            {searchData.length > 0 && (
                <ComparisonPaper elevation={3}>
                    <Grid container>
                        <Grid item xs>
                            <Typography margin="10px" variant="body1">
                                Fixed Income Credit Ratings
                            </Typography>
                            <Box sx={{ height: '100%', overflow: 'auto' }}>
                                <EnhancedTable
                                    enableSearch={false}
                                    enablePagination={false}
                                    columns={comparisonDetail?.column || []}
                                    data={comparisonDetail?.data || []}
                                    enableSort={false}
                                    hideToolBar={true}
                                    tablestyle={{ maxHeight: 'max-content' }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </ComparisonPaper>
            )}
        </>
    );
};
export default CreditRating;
